<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.system.setting.types.register') }}
        </h2>

        <router-link to="/system/setting" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.system.setting.title") }}
        </router-link>
    </div>

    <custom-table
        :title="$t('formElement.title')"
        :subTitle="$t('formElement.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:type="{ row: record }">
            {{ resolveDatum(elementTypes, record.type_id, 'id').name }} {{ record.options && record.options.type ? sprintf('(%s)', [$t('formElement.inputTypes.' + record.options.type)]) : '' }}
        </template>
        <template v-slot:title="{ row: record }">
            {{ resolveDatum(record.translations, $root.defaultLanguage.id, 'language_id').title }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchElement(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>


    <div class="modal fade" id="kt_modal_element" ref="elementModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form" ref="elementForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <!--<div class="fv-row mb-7" v-if="form.updateStatus">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('common.language') }}</label>
                                <el-form-item prop="locale" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.locale" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedLanguage">
                                        <el-option v-for="(language, languageIndex) in $root.languages" :key="languageIndex" :value="language.code" :label="language.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>-->

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('formElement.cols.name') }}</label>
                                <el-form-item prop="data.name" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.name" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('formElement.cols.title') }}</label>
                                <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.translate.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mt-7 mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('formElement.cols.description') }}</label>
                                <el-form-item prop="translate.description">
                                    <el-input v-model="form.translate.description" type="textarea" rows="3"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('formElement.cols.type') }}</label>
                                <el-form-item prop="data.type_id" :rules="$validation.getMessage(['required'])" class="mb-0">
                                    <el-select v-model="form.data.type_id" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedType" :disabled="form.updateStatus">
                                        <el-option v-for="(type, typeIndex) in elementTypes" :key="typeIndex" :value="type.id" :label="type.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mt-2" v-if="form.options.type.options.length">
                                <el-form-item prop="options.type.model" :rules="$validation.getMessage(['required'])" class="mb-0">
                                    <el-select v-model="form.options.type.model" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedTypeOption" :disabled="form.updateStatus">
                                        <el-option v-for="(typeOption, typeOptionIndex) in form.options.type.options" :key="typeOptionIndex" :value="typeOption.value" :label="typeOption.label"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mt-2" v-if="elementAttributeList.length">
                                <el-form-item prop="options.attribute.model" class="mb-0">
                                    <el-select v-model="form.options.attribute.model" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedAttribute">
                                        <el-option v-for="(attribute, attributeIndex) in elementAttributeList" :key="attribute" :value="attribute" :label="$t('formElement.attributes.' + attribute)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <template v-if="form.options.attribute.selections.length">
                                <el-divider content-position="center" class="mt-12 mb-10">
                                    {{ $t('formElement.elementAttributeSettings') }}
                                </el-divider>

                                <div class="fv-row mt-1 d-flex align-items-center" v-for="(attribute, attributeIndex) in form.options.attribute.selections" :key="attributeIndex">
                                    <label class="fs-6 fw-bold mb-2 w-25">{{ attribute.label }}</label>
                                    <el-form-item class="mb-0 w-75">
                                        <div class="d-flex w-100 justify-content-between">
                                            <el-checkbox v-if="attribute.type == 'checkbox'" v-model="attribute.value" :disabled="form.updateStatus &&  attribute.name == 'multiple'"/>
                                            <el-select v-else-if="attribute.type == 'acceptSelect'" v-model="attribute.value" class="w-100" :placeholder="$t('common.chooseSelect')" multiple>
                                                <el-option v-for="(fileExtension, fileExtensionIndex) in $root.fileSupported.extensions" :key="fileExtensionIndex" :value="fileExtension" :label="fileExtension"></el-option>
                                            </el-select>
                                            <el-input v-else :type="attribute.type" v-model="attribute.value" :min="attribute.type == 'number' && 0"/>
                                            <el-button class="ms-1" type="danger" v-on:click="deleteAttributeSelection(attributeIndex)" :disabled="form.updateStatus && attribute.name == 'multiple'"><i class="bi bi-trash"/></el-button>
                                        </div>
                                    </el-form-item>
                                </div>
                            </template>

                            <template v-if="form.options.type.addable.status">
                                <el-divider content-position="center" class="mt-10 mb-10">
                                    {{ $t('formElement.elementItems') }}
                                </el-divider>
                                <div class="fv-row">
                                    <div class="d-flex justify-content-between align-items-center mb-1" v-for="(item, itemIndex) in form.options.type.addable.items" :key="itemIndex">
                                        <el-input v-model="form.options.type.addable.items[itemIndex].val" type="text"/>
                                        <el-input v-model="form.options.type.addable.items[itemIndex].text" type="text" class="ms-1"/>
                                        <el-button class="ms-1" type="danger" v-on:click="deleteItem(itemIndex)"><i class="bi bi-trash"/></el-button>
                                    </div>

                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <el-input type="text" v-model="form.options.type.addable.models.val" :placeholder="$t('formElement.addItem.value')"/>
                                        <el-input type="text" v-model="form.options.type.addable.models.text" :placeholder="$t('formElement.addItem.text')" class="ms-1"/>
                                        <el-button class="ms-1" type="primary" v-on:click="newAddItem"><i class="bi bi-plus"/></el-button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("formElement.cols.name"),
                    key: "name",
                },
                {
                    name: this.$t("formElement.cols.type"),
                    scopedSlots: {customRender: "type"}
                },
                {
                    name: this.$t("formElement.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("common.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id
                },
                data: {
                    sort: 1,
                    active: true
                },
                options: {
                    type: {
                        model: undefined,
                        options: [],
                        addable: {
                            status: false,
                            models: {},
                            items: []
                        }
                    },
                    attribute: {
                        model: undefined,
                        currentList: [],
                        selections: []
                    }
                }
            },
            selectedRowKeys: [],
            elementsOptions: {
                input: {
                    types: ['text', 'email', 'password', 'number', 'radio', 'checkbox'], //, 'file'
                    attributes: {
                        text: ['class', 'placeholder', 'size', 'maxlength', 'minlength', 'required'],
                        number: ['class', 'placeholder', 'max', 'min', 'step', 'required'],
                        email: ['class', 'placeholder', 'required'],
                        password: ['class', 'placeholder', 'required', 'maxlength', 'minlength'],
                        radio: ['class', 'required'],
                        checkbox: ['class', 'required'],
                        file: ['class', 'required', 'accept']
                    }
                },
                textarea: {
                    attributes: ['class', 'placeholder', 'required', 'rows', 'cols']
                },
                select: {
                    addItems: true,
                    attributes: ['class', 'placeholder', 'required', 'multiple']
                },
                radio_group: {
                    addItems: true,
                    attributes: ['class', 'required']
                },
                checkbox_group: {
                    addItems: true,
                    attributes: ['class', 'required']
                },
                state: {
                    attributes: ['class', 'placeholder', 'required', 'multiple']
                },
                city: {
                    attributes: ['class', 'placeholder', 'required', 'multiple']
                }
            },
            elementTranslateAttributes: ['placeholder']
        }
    },
    computed: {
        table() {
            return this.$store.state.system.setting.registerElement.table;
        },
        elementTypes() {
            return this.$store.state.system.setting.registerElement.type.table.data.filter((type) => !['radio_group', 'checkbox_group'].includes(type.code));
        },
        elementAttributeList(){
            let list = this.cloneData(this.form.options.attribute.currentList);

            if(this.form.options.attribute.selections.length){
                let selectionAttributes = this.form.options.attribute.selections.map((selection) => {
                    return selection.name;
                })

                list = list.filter((item) => {
                    return !selectionAttributes.includes(item);
                })
            }

            return list;
        }
    },
    mounted(){
        this.$store.dispatch('system/setting/registerElement/get', {});
        this.$store.dispatch('system/setting/registerElement/type/get');
    },
    methods: {
        findElementType(key, value){
            let found = this.elementTypes.filter((type) => {
                return type[key] == value;
            });

            return found.length == 1 ? found[0] : {}
        },
        resetOptionsData(){
            this.form.options = {
                type: {
                    model: undefined,
                    options: [],
                    addable: {
                        status: false,
                        model: undefined,
                        items: []
                    }
                },
                attribute: {
                    model: undefined,
                    currentList: [],
                    selections: []
                }
            }
        },
        resetAttributeData(){
            this.form.options.attribute.selections = [];
        },
        newElement(){
            this.resetOptionsData();

            this.form.updateStatus = false;
            this.form.translate = {
                language_id: this.$root.defaultLanguage.id
            };
            this.form.data = {
                sort: 1
            };
            this.form.title = this.$t("formElement.new");

            this.showModal(this.$refs.elementModal);
        },
        fetchElement(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("formElement.edit");

            this.resetOptionsData();
            this.form.data = this.cloneData(record);

            this.selectedType();
            this.selectedLanguage();

            let optionKeys = Object.keys(record.options);

            optionKeys.forEach((optionKey) => {
                let optionValue = record.options[optionKey];

                if (optionKey == 'type') {
                    this.form.options.type.model = optionValue;
                    this.form.options.type.addable.status = ['radio', 'checkbox'].includes(this.form.options.type.model);
                    let elementOptions = this.elementsOptions[this.findElementType('id', this.form.data.type_id).code];
                    this.form.options.attribute.currentList = this.cloneData(elementOptions.attributes[this.form.options.type.model]);
                } else {
                    this.selectedAttribute(optionKey, optionValue);
                }
            });

            this.showModal(this.$refs.elementModal);
        },
        onSubmit(){
            this.$refs.elementForm.validate((valid) => {
                if(valid) {
                    if (!this.validateFormData()) {
                        return;
                    }

                    let formData = this.prepareFormData();
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['system_setting_register_element'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.elementModal);
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data);
                        }).finally(() => {
                            this.form.loading = false;
                        });
                    }else {
                        this.axios.post(this.endpoints['system_setting_register_element'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.elementModal);
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data);
                        }).finally(() => {
                            this.form.loading = false;
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        validateFormData(showWarning = true) {
            let warning = null;

            if (this.form.options.type.addable.status) {
                let typeAddableItems = this.form.options.type.addable.items.filter((item) => {
                    return item.val.trim().length && item.text.trim().length;
                });

                if (this.form.options.type.addable.items.length != typeAddableItems.length) {
                    warning = "fillAddedElementItems";
                }

                if (!this.form.options.type.addable.items.length) {
                    warning = "elementRequiredItems";
                }
            }

            if (warning && showWarning) {
                this.showActionWarning(warning, true);
            }

            return warning == null;
        },
        prepareFormData(){
            let formData = this.form.data;
            let formTranslateData = this.form.translate;

            let options = {};
            let translateOptions = {};

            this.form.options.attribute.selections.forEach((selection) => {
                if (selection.value != null) {
                    if (selection.type == 'number') {
                        selection.value = parseFloat(selection.value);
                    }

                    if(this.elementTranslateAttributes.includes(selection.name)){
                        translateOptions[selection.name] = selection.value;
                    } else {
                        options[selection.name] = selection.value;
                    }
                }
            });

            if (this.form.options.type.model && this.elementsOptions[this.findElementType('id', this.form.data.type_id).code].types) {
                options['type'] = this.form.options.type.model;
            }

            formData.options = options;
            formTranslateData.options = translateOptions;

            formData.translate = formTranslateData;

            return formData;
        },
        deleteRecord(id){
            this.$store.dispatch("system/setting/registerElement/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        selectedLanguage(){
            let translate = this.cloneData(this.resolveDatum(this.form.data.translations, this.form.translate.language_id, 'language_id', {options: []}));

            if(this.elementTranslateAttributes.length){
                this.elementTranslateAttributes.forEach((attribute) => {
                    let translateValue = translate.options[attribute] ? translate.options[attribute] : null;
                    let selectionIndex = this.form.options.attribute.selections.findIndex(selection => selection.name == attribute);
                    if(selectionIndex  !== -1){
                        this.form.options.attribute.selections[selectionIndex].value = translateValue;
                    } else if(translateValue) {
                        this.selectedAttribute(attribute, translateValue);
                    }
                });
            }

            this.form.translate = translate;
            this.form.options.type.addable.items = translate.items ? translate.items : [];
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newElement();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("system/setting/registerElement/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("system/setting/registerElement/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        selectedType(){
            this.resetAttributeData();

            let elementOptions = this.elementsOptions[this.findElementType('id', this.form.data.type_id).code];

            if (elementOptions.types) {
                let options = [];

                elementOptions.types.forEach((item) => {
                    options.push({
                        label: this.$t('formElement.inputTypes.' + item),
                        value: item
                    });
                });

                this.form.options.type.options = options;
            } else {
                this.form.options.type.options = [];
                this.form.options.attribute.currentList = this.cloneData(elementOptions.attributes);
            }

            this.form.options.type.model = undefined;
            this.form.options.type.addable = {
                status: ((elementOptions.addItems) ? true : false),
                models: {},
                items: [],
            };
        },
        selectedTypeOption(){
            this.resetAttributeData();

            let elementOptions = this.elementsOptions[this.findElementType('id', this.form.data.type_id).code];

            this.form.options.attribute.currentList = this.cloneData(elementOptions.attributes[this.form.options.type.model]);
            this.form.options.type.addable.status = ['radio', 'checkbox'].includes(this.form.options.type.model);
            this.form.options.type.addable.items = [];
        },
        selectedAttribute(key = null, value = null) {
            if (key) {
                this.form.options.attribute.model = key;
            }

            let attributeInputType = "number";
            let attributeInputValue = value;

            if (['class', 'placeholder'].includes(this.form.options.attribute.model)) {
                attributeInputType = "text";
            }

            if(this.form.options.attribute.model == 'accept'){
                attributeInputType = 'acceptSelect'
            }

            if (['required', 'multiple'].includes(this.form.options.attribute.model)) {
                attributeInputType = 'checkbox';
                attributeInputValue = value != null ? value : true;
            }

            this.form.options.attribute.selections.push({
                label: this.$t('formElement.attributes.' + this.form.options.attribute.model),
                name: this.form.options.attribute.model,
                type: attributeInputType,
                value: attributeInputValue
            });

            this.form.options.attribute.model = undefined;
        },
        deleteAttributeSelection(index) {
            this.form.options.attribute.selections.splice(index, 1);
        },
        newAddItem() {
            let typeAddableModel = this.form.options.type.addable.models;

            if(!(typeAddableModel.val && typeAddableModel.text && typeAddableModel.val.trim().length && typeAddableModel.text.trim().length)){
                this.showActionWarning('fillElementItems', true);
                return;
            }

            this.form.options.type.addable.items.push(typeAddableModel);
            this.form.translate.items = this.form.options.type.addable.items;
            this.form.options.type.addable.models = {};
        },
        deleteItem(index) {
            this.form.options.type.addable.items.splice(index, 1);
            this.form.translate.items = this.form.options.type.addable.items;
        },
        showActionWarning(warning, translate = false) {
            this.$notify({
                type: 'warning',
                title: this.$t("messages.warning"),
                message: (translate ? this.$t('formElement.warnings.' + warning) : warning),
            })
        },
    }
}
</script>

<style>
.el-button {
    padding: 8px 15px;
}
.bi {
    font-size: 1.2rem;
}
</style>